import { BUTTON_STYLES } from './kog-button.utils.ts';

export const ROUND_BUTTON_SIZES = ['medium', 'small', 'x-small', 'xx-small'] as const;

export const ROUND_BUTTON_ICON_SIZES = ['m', 's', 'xs'] as const;

export const ROUND_BUTTON_STYLES = [
  ...BUTTON_STYLES,
  'secondary-basic',
  'secondary-outline',
] as const;
