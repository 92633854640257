<template>
  <button
    ref="root"
    v-kog-description:[getUniqueId(`kog-button`)]="description || tooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltip,
      popperClass: 'text-center',
      boundary: 'document.body',
    }"
    class="KogButton inline-block"
    :class="cssClasses"
    :disabled="disabled || isLoading ? 'true' : undefined"
    :aria-disabled="disabled || isLoading ? 'true' : undefined"
    :aria-label="ariaLabel || label"
    :aria-haspopup="hasDropdown ? true : undefined"
    :aria-expanded="hasDropdown ? (isDropdownOpen ? 'true' : 'false') : undefined"
    :aria-controls="controlsElementId"
    @click="handleClick"
  >
    <template v-if="isLoading && hasDropdown">
      <div class="flexContainer flexContainer-alignCenter">
        <kog-icon
          v-if="leftIcon"
          theme="custom"
          :icon-class="leftIcon.iconClass"
          :fa-style="leftIcon.iconStyle"
          :size="`${leftIcon.iconSize}-touch`"
        />
        <span class="padd-left-xs padd-right-xs">{{ label }}</span>
        <kog-button-loading-spinner />
      </div>
    </template>

    <template v-else-if="isLoading && (leftIcon || rightIcon)">
      <div class="flexContainer flexContainer-alignCenter">
        <kog-button-loading-spinner />
        <span class="padd-left-xs padd-right-xs">{{ label }}</span>
      </div>
    </template>

    <template v-else>
      <div class="KogButton-loader--covering">
        <kog-button-loading-spinner v-if="isLoading" />
      </div>
      <div
        class="flexContainer flexContainer-alignCenter"
        :class="{ 'KogButton-content--loading': isLoading }"
      >
        <kog-icon
          v-if="leftIcon"
          theme="custom"
          :icon-class="leftIcon.iconClass"
          :fa-style="leftIcon.iconStyle"
          :size="`${leftIcon.iconSize}-touch`"
          :is-image-icon="leftIcon.isImageIcon"
          :is-svg-icon="leftIcon.isSvgIcon"
        />
        <span class="padd-left-xs padd-right-xs">{{ label }}</span>
        <kog-icon
          v-if="hasDropdown && showDropdownArrow"
          theme="custom"
          :icon-class="isDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'"
          fa-style="solid"
          size="s-touch"
        />
        <kog-icon
          v-else-if="rightIcon"
          theme="custom"
          :icon-class="rightIcon.iconClass"
          :fa-style="rightIcon.iconStyle"
          :size="`${rightIcon.iconSize}-touch`"
          :is-image-icon="rightIcon.isImageIcon"
          :is-svg-icon="rightIcon.isSvgIcon"
        />
      </div>
    </template>
  </button>
</template>

<script setup lang="ts">
import { computed, defineEmits, useTemplateRef } from 'vue';
import { VTooltip as vTooltip } from 'floating-vue';

import type { PropType } from 'vue';
import type { ButtonIcon, ButtonSize, ButtonStyle } from './kog-button.types.ts';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import vKogDescription from 'sharedApp/directives/kog-description.js';

import KogButtonLoadingSpinner from './kog-button-loading-spinner.vue';
import {
  BUTTON_ICON_SIZES,
  BUTTON_ICON_STYLES,
  BUTTON_SIZES,
  BUTTON_STYLES,
} from './kog-button.utils.ts';

const root = useTemplateRef('root');

const props = defineProps({
  /**
   * Optional message to be read out by assistive screen reader technologies.
   */
  ariaLabel: {
    type: String,
    default: '',
  },
  /**
   * Controls if button is clickable or not.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Controls if caret icon should be shown to the right of the label.<br>
   * Should only be used by `kog-dropdown-button`.
   */
  hasDropdown: {
    type: Boolean,
    default: false,
  },
  /**
   * With dropdown enabled, used as aria-controls value.
   * */
  controlsElementId: {
    type: String,
    default: null,
  },
  /**
   * Icon to be shown to the left of the button label.
   * can be used with `rightIcon` to show icons on both sides.
   */
  leftIcon: {
    type: Object as PropType<ButtonIcon>,
    default: null,
    validator(value: ButtonIcon) {
      return (
        !!value.iconClass &&
        (!value.iconStyle || BUTTON_ICON_STYLES.includes(value.iconStyle)) &&
        (!value.iconSize || BUTTON_ICON_SIZES.includes(value.iconSize))
      );
    },
  },
  /**
   * Icon to be shown to the right of the button label.
   * can be used with `leftIcon` to show icons on both sides.
   * NOTE: This prop value should not be used together with `hasDropdown: true`.
   */
  rightIcon: {
    type: Object as PropType<ButtonIcon>,
    default: null,
    validator(value: ButtonIcon) {
      return (
        !!value.iconClass &&
        (!value.iconStyle || BUTTON_ICON_STYLES.includes(value.iconStyle)) &&
        (!value.iconSize || BUTTON_ICON_SIZES.includes(value.iconSize))
      );
    },
  },
  /**
   * Controls the caret icon direction and the background, border, and color of button.<br>
   * Only used with `kog-dropdown-button`.
   */
  isDropdownOpen: {
    type: Boolean,
    default: false,
  },
  /**
   * When having dropdown, controls if the up / down arrow is shown.
   * */
  showDropdownArrow: {
    type: Boolean,
    default: true,
  },
  /**
   * Toggles if loading spinner should be shown in button.
   */
  isLoading: {
    type: Boolean,
    default: false,
  },
  /**
   * The text that should be displayed in the button.
   */
  label: {
    type: String,
    required: true,
  },
  /**
   * Controls padding, height, and minimum width of the button.
   */
  size: {
    type: String as PropType<ButtonSize>,
    default: 'medium',
    validator(value: ButtonSize) {
      return !value || BUTTON_SIZES.includes(value);
    },
  },
  /**
   * Controls the styles of the button in terms of background,<br>
   * borders, text, and hover/focus/disabled states.
   */
  buttonStyle: {
    type: String as PropType<ButtonStyle>,
    default: 'default-hollow',
    validator(value: ButtonStyle) {
      return BUTTON_STYLES.includes(value);
    },
  },
  /**
   * Optional text to be shown in tooltip on button hover.<br>
   * Tooltip is centered above button.
   */
  tooltip: {
    type: String,
    default: '',
  },
  /**
   * Prop to add extra description to the button for users
   *  using screen readers.
   */
  description: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['click']);

const { getUniqueId } = useUniqueId();

const cssClasses = computed(() => {
  const styleClass = `KogButton--${props.buttonStyle}`;
  const sizeClass = `KogButton--${props.size}`;
  let loadingClass = '';
  if (props.isLoading) {
    loadingClass = `KogButton--${props.buttonStyle}--loading`;
  }
  const dropdownClass = props.isDropdownOpen ? `KogButton--${props.buttonStyle}--dropdownOpen` : '';

  return [styleClass, sizeClass, loadingClass, dropdownClass];
});

function handleClick(_: Event) {
  if (props.disabled || props.isLoading) {
    return;
  }
  /**
   * Emitted when the user clicks the button
   * */
  emit('click', _);
}

defineExpose({
  root,
});
</script>

<style scoped>
/* default style */

.KogButton {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 40px;
  padding: 8px 24px;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: 24px;
  color: var(--kog-button-default-hollow-label-default);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  background: var(--kog-button-default-hollow-background-default);
  border-color: var(--kog-button-default-hollow-border-default);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: none;

  transition:
    background 0.1s ease,
    border-color 0.1s ease;
}

.KogButton:focus {
  color: var(--kog-button-default-hollow-label-focus);
}

.KogButton[aria-disabled='true'] {
  cursor: not-allowed;
  color: var(--kog-button-default-hollow-label-disabled);
  background: var(--kog-button-default-hollow-background-disabled);
  border-color: var(--kog-button-default-hollow-border-disabled);
}

.KogButton:hover:not([aria-disabled='true']) {
  color: var(--kog-button-default-hollow-label-hover);
  background: var(--kog-button-default-hollow-background-hover);
}

.KogButton:active:not([aria-disabled='true']),
.KogButton.KogButton--default-hollow--dropdownOpen {
  color: var(--kog-button-default-hollow-label-active);
  border-color: var(--kog-button-default-hollow-border-active);
}

.KogButton--default-hollow--loading[aria-disabled='true'] {
  color: var(--kog-button-default-hollow-label-loading);
  background: var(--kog-button-default-hollow-background-loading);
  border-color: var(--kog-button-default-hollow-border-loading);
}

.KogButton-loader--covering {
  position: absolute;
}

.KogButton-content--loading {
  visibility: hidden;
}

/* default-fill style */
.KogButton--default-fill {
  color: var(--kog-button-default-fill-label-default);
  background: var(--kog-button-default-fill-background-default);
  border-color: var(--kog-button-default-fill-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--default-fill:focus {
  color: var(--kog-button-default-fill-label-focus);
  background: var(--kog-button-default-fill-background-focus);
  border-color: var(--kog-button-default-fill-border-focus);
}

.KogButton--default-fill[aria-disabled='true'] {
  color: var(--kog-button-default-fill-label-disabled);
  background: var(--kog-button-default-fill-background-disabled);
  border-color: var(--kog-button-default-fill-border-disabled);
}

.KogButton--default-fill:hover:not([aria-disabled='true']) {
  color: var(--kog-button-default-fill-label-hover);
  background: var(--kog-button-default-fill-background-hover);
  border-color: var(--kog-button-default-fill-border-hover);
}

.KogButton--default-fill:active:not([aria-disabled='true']),
.KogButton.KogButton--default-fill--dropdownOpen {
  color: var(--kog-button-default-fill-label-active);
  background: var(--kog-button-default-fill-background-active);
  border-color: var(--kog-button-default-fill-border-active);
}

.KogButton--default-fill--loading[aria-disabled='true'] {
  background: var(--kog-button-default-fill-background-loading);
  border-color: var(--kog-button-default-fill-border-loading);
}

/* primary style */

.KogButton--primary {
  color: var(--kog-button-primary-label-default);
  background: var(--kog-button-primary-background-default);
  border-color: var(--kog-button-primary-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--primary:focus {
  color: var(--kog-button-primary-label-focus);
}

.KogButton--primary[aria-disabled='true'] {
  color: var(--kog-button-primary-label-disabled);
  background: var(--kog-button-primary-background-disabled);
  border-color: var(--kog-button-primary-border-disabled);
}

.KogButton--primary:hover:not([aria-disabled='true']) {
  color: var(--kog-button-primary-label-hover);
  background: var(--kog-button-primary-background-hover);
  border-color: var(--kog-button-primary-border-hover);
}

.KogButton--primary:active:not([aria-disabled='true']),
.KogButton.KogButton--primary--dropdownOpen {
  color: var(--kog-button-primary-label-active);
  background: var(--kog-button-primary-background-active);
  border-color: var(--kog-button-primary-border-active);
}

.KogButton--primary--loading[aria-disabled='true'] {
  color: var(--kog-button-primary-label-loading);
  background: var(--kog-button-primary-background-loading);
  border-color: var(--kog-button-primary-border-loading);
}

.KogButton--primary--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-primary-label-loading);
}

/* accent style */

.KogButton--accent {
  color: var(--kog-button-accent-label-default);
  background: var(--kog-button-accent-background-default);
  border-color: var(--kog-button-accent-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--accent:focus {
  color: var(--kog-button-accent-label-focus);
}

.KogButton--accent[aria-disabled='true'] {
  color: var(--kog-button-accent-label-disabled);
  background: var(--kog-button-accent-background-disabled);
  border-color: var(--kog-button-accent-border-disabled);
}

.KogButton--accent:hover:not([aria-disabled='true']) {
  color: var(--kog-button-accent-label-hover);
  background: var(--kog-button-accent-background-hover);
  border-color: var(--kog-button-accent-border-hover);
}

.KogButton--accent:active:not([aria-disabled='true']),
.KogButton.KogButton--accent--dropdownOpen {
  color: var(--kog-button-accent-label-active);
  background: var(--kog-button-accent-background-active);
  border-color: var(--kog-button-accent-border-active);
}

.KogButton--accent--loading[aria-disabled='true'] {
  color: var(--kog-button-accent-label-loading);
  background: var(--kog-button-accent-loading-background);
  border-color: var(--kog-button-accent-border-loading);
}

.KogButton--accent--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-accent-label-loading);
}

/* danger style */

.KogButton--danger {
  color: var(--kog-button-danger-label-default);
  background: var(--kog-button-danger-background-default);
  border-color: var(--kog-button-danger-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--danger:focus {
  color: var(--kog-button-danger-label-focus);
}

.KogButton--danger[aria-disabled='true'] {
  color: var(--kog-button-danger-label-disabled);
  background: var(--kog-button-danger-background-disabled);
  border-color: var(--kog-button-danger-border-disabled);
}

.KogButton--danger:hover:not([aria-disabled='true']) {
  color: var(--kog-button-danger-label-hover);
  background: var(--kog-button-danger-background-hover);
  border-color: var(--kog-button-danger-border-hover);
}

.KogButton--danger:active:not([aria-disabled='true']),
.KogButton.KogButton--danger--dropdownOpen {
  color: var(--kog-button-danger-label-active);
  background: var(--kog-button-danger-background-active);
  border-color: var(--kog-button-danger-border-active);
}

.KogButton--danger--loading[aria-disabled='true'] {
  color: var(--kog-button-danger-label-loading);
  background: var(--kog-button-danger-loading-background);
  border-color: var(--kog-button-danger-border-loading);
}

.KogButton--danger--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-danger-label-loading);
}

/* positive style */

.KogButton--positive {
  color: var(--kog-button-success-label-default);
  background: var(--kog-button-success-background-default);
  border-color: var(--kog-button-success-border-default);
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--positive:focus {
  color: var(--kog-button-success-label-focus);
}

.KogButton--positive[aria-disabled='true'] {
  color: var(--kog-button-success-label-disabled);
  background: var(--kog-button-success-background-disabled);
  border-color: var(--kog-button-success-border-disabled);
}

.KogButton--positive:hover:not([aria-disabled='true']) {
  color: var(--kog-button-success-label-hover);
  background: var(--kog-button-success-background-hover);
  border-color: var(--kog-button-success-border-hover);
}

.KogButton--positive:active:not([aria-disabled='true']),
.KogButton.KogButton--positive--dropdownOpen {
  color: var(--kog-button-success-label-active);
  background: var(--kog-button-success-background-active);
  border-color: var(--kog-button-success-border-active);
}

.KogButton--positive--loading[aria-disabled='true'] {
  color: var(--kog-button-success-label-loading);
  background: var(--kog-button-success-background-loading);
  border-color: var(--kog-button-success-border-loading);
}

.KogButton--positive--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-success-label-loading);
}

/* basic style */

.KogButton--basic {
  color: var(--kog-button-basic-label-default);
  background: transparent;
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--basic:not([aria-disabled='true']) {
  border: none;
}

.KogButton--basic:focus {
  color: var(--kog-button-basic-label-focus);
}

.KogButton--basic[aria-disabled='true'] {
  color: var(--kog-button-basic-label-disabled);
  background: var(--kog-button-basic-background-disabled);
  border-color: var(--kog-button-basic-border-disabled);
}

.KogButton--basic:hover:not([aria-disabled='true']) {
  color: var(--kog-button-basic-label-default);
  background: var(--kog-button-basic-background-hover);
}

.KogButton--basic:active:not([aria-disabled='true']),
.KogButton.KogButton--basic--dropdownOpen {
  color: var(--kog-button-basic-label-active);
  background: var(--kog-button-basic-background-active);
}

.KogButton--basic--loading[aria-disabled='true'] {
  color: var(--kog-button-basic-label-loading);
  background: var(--kog-button-basic-background-loading);
}

.KogButton--basic--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-basic-label-loading);
}

/* basic-inverted style */
.KogButton--basic-inverted {
  color: var(--kog-button-basic-inverted-label-default);
  background: var(--kog-button-basic-inverted-background-default);
  border: None;
  transition:
    background 0.2s ease,
    border-color 0.2s ease;
}

.KogButton--basic-inverted:focus {
  color: var(--kog-button-basic-inverted-label-focus);
  background: var(--kog-button-basic-inverted-background-focus);
}

.KogButton--basic-inverted[aria-disabled='true'] {
  color: var(--kog-button-basic-inverted-label-disabled);
  background: var(--kog-button-basic-inverted-background-disabled);
}

.KogButton--basic-inverted:hover:not([aria-disabled='true']) {
  color: var(--kog-button-basic-inverted-label-hover);
  background: var(--kog-button-basic-inverted-background-hover);
}

.KogButton--basic-inverted:active:not([aria-disabled='true']),
.KogButton.KogButton--basic-inverted--dropdownOpen {
  color: var(--kog-button-basic-inverted-label-active);
  background: var(--kog-button-basic-inverted-background-active);
}

.KogButton--basic-inverted--loading[aria-disabled='true'] {
  color: var(--kog-button-primary-label-loading);
  background: var(--kog-button-primary-background-loading);
  border-color: var(--kog-button-primary-border-loading);
}

.KogButton--basic-inverted--loading :deep(.KogButtonLoadingSpinner-circle::before) {
  background: var(--kog-button-primary-label-loading);
}

/* sizes */

.KogButton--large {
  min-width: 130px;
  height: 48px;
  padding: 12px;
}

.KogButton--medium {
  min-width: 130px;
  height: 40px;
  padding: 8px;
}

.KogButton--small {
  min-width: 85px;
  height: 32px;
  padding: 4px;
}
</style>
