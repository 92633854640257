import { trackMixpanelEventViaBackend } from '@apis/mixpanel.ts';

const mixpanel = require('mixpanel-browser');

const mixpanelModule = {
  authToken: null,
  userConstants: null,
  eventQueue: [],
  backendEventQueue: [],
  defaultProperties: {},
  waitForDefaultProperties: false,
  areDefaultPropertiesSet: false,

  isMixPanelEnabled() {
    return !!mixpanelModule.authToken;
  },

  areDefaultPropertiesReady() {
    return mixpanelModule.waitForDefaultProperties ? mixpanelModule.areDefaultPropertiesSet : true;
  },

  setWaitForDefaultProperties() {
    mixpanelModule.waitForDefaultProperties = true;
    mixpanelModule.areDefaultPropertiesSet = false;
  },

  addEventToQueue(event) {
    mixpanelModule.eventQueue.push(event);
  },

  addEventToBackendQueue(event) {
    mixpanelModule.backendEventQueue.push(event);
  },

  handleQueuedEvents() {
    const defaultProperties = mixpanelModule.getDefaultPropertiesForEvents(false);
    mixpanelModule.eventQueue.forEach(event => {
      mixpanel.track(event.eventName, { ...defaultProperties, ...event.eventProperties });
    });
    mixpanelModule.eventQueue = [];

    mixpanelModule.backendEventQueue.forEach(event => {
      mixpanelModule.sendBackendEvent(event.eventName, {
        ...defaultProperties,
        ...event.eventProperties,
      });
    });
    mixpanelModule.backendEventQueue = [];
  },

  trackEvent(eventName, eventProperties) {
    if (mixpanelModule.isMixPanelEnabled() && mixpanelModule.areDefaultPropertiesReady()) {
      const defaultProperties = mixpanelModule.getDefaultPropertiesForEvents(false);
      mixpanel.track(eventName, {
        ...defaultProperties,
        ...eventProperties,
      });
    } else {
      mixpanelModule.addEventToQueue({ eventName, eventProperties });
    }
  },

  async trackEventViaBackend(eventName, eventProperties) {
    if (mixpanelModule.areDefaultPropertiesReady()) {
      await mixpanelModule.sendBackendEvent(eventName, eventProperties);
    } else {
      mixpanelModule.addEventToBackendQueue({ eventName, eventProperties });
    }
  },

  sendBackendEvent(eventName, eventProperties) {
    const defaultProperties = mixpanelModule.getDefaultPropertiesForEvents(true);
    return trackMixpanelEventViaBackend(eventName, {
      ...defaultProperties,
      ...eventProperties,
    });
  },

  incrementCounterProperty(propertyName) {
    if (mixpanelModule.isMixPanelEnabled()) {
      const value = mixpanel.get_property(propertyName);
      const update = {};
      if (value && typeof value === 'number') {
        update[propertyName] = value + 1;
      } else {
        update[propertyName] = 1;
      }
      mixpanel.register(update);
    }
  },

  registerCounterProperty(propertyName) {
    if (mixpanelModule.isMixPanelEnabled()) {
      const value = mixpanel.get_property(propertyName);
      mixpanel.register({ propertyName: value });
    }
  },

  resetDefaultPropertiesForEvents() {
    mixpanelModule.defaultProperties = {};
    mixpanelModule.areDefaultPropertiesSet = false;
  },

  registerDefaultPropertiesForEvents(properties) {
    mixpanelModule.defaultProperties = {
      ...mixpanelModule.defaultProperties,
      ...properties,
    };
    mixpanelModule.areDefaultPropertiesSet = true;
    if (mixpanelModule.waitForDefaultProperties && mixpanelModule.isMixPanelEnabled()) {
      mixpanelModule.handleQueuedEvents();
    }
  },

  getDefaultPropertiesForEvents(includeMixpanelPersistentProperties) {
    let mixpanelProperties = {};
    if (mixpanelModule.isMixPanelEnabled() && includeMixpanelPersistentProperties) {
      mixpanelProperties = mixpanel.persistence.properties();
    }
    return {
      ...mixpanelProperties,
      ...mixpanelModule.defaultProperties,
    };
  },

  formatDate(isoFormatDateAsString) {
    const createdDate = new Date(isoFormatDateAsString);
    return createdDate.toISOString();
  },

  setupPeopleTracking(additionalPeopleProperties) {
    if (!mixpanelModule.isMixPanelEnabled()) {
      return;
    }
    const user = mixpanelModule.userConstants.User;
    mixpanel.identify(user.id);
    const peopleProperties = {
      $email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      user_id: user.id,
      account_created: mixpanelModule.formatDate(user.created),
    };

    const mergedProperties = Object.assign(peopleProperties, additionalPeopleProperties);
    mixpanel.people.set(mergedProperties);
  },

  init(authToken, userConstants) {
    if (authToken) {
      mixpanelModule.authToken = authToken;
      mixpanelModule.userConstants = userConstants;
      mixpanelModule.resetDefaultPropertiesForEvents();
      mixpanel.init(authToken, {
        cookie_expiration: 30,
      });
      mixpanel.identify(userConstants.User.id);
      mixpanelModule.handleQueuedEvents();
    }
  },

  trackLinks(element, eventName, eventProperties) {
    if (mixpanelModule.isMixPanelEnabled()) {
      mixpanel.track_links(element, eventName, eventProperties);
    }
  },
};

export default mixpanelModule;
