export const BUTTON_SIZES = ['small', 'medium', 'large'] as const;

export const BUTTON_STYLES = [
  'default-hollow',
  'default-fill',
  'primary',
  'accent',
  'basic',
  'basic-inverted',
  'danger',
  'positive',
] as const;

export const BUTTON_ICON_SIZES = ['m', 's'] as const;

export const BUTTON_ICON_STYLES = ['solid', 'regular', 'light'] as const;

export const BUTTON_ICON_POSITIONS = ['left', 'right'] as const;
